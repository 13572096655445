import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { loggedActivated, loggedChildActivated } from 'src/app/core/auth-guard/islogged-guard/is-logged.guard';
import { BaseComponent } from './base.component';

const childRoutes: Routes = [
  {
    path: 'planner',
    loadChildren: () => import('./planner/planner.module').then(m => m.PlannerModule),
  },
  {
    path: 'metrics',
    loadComponent: () => import('./metrics/page/metrics.component').then(m => m.MetricsComponent),
  },
  {
    path: 'statistics',
    loadChildren: () => import('./statistics/statistics.module').then(m => m.StatisticsModule),
  },
  {
    path: 'simulations-light',
    loadComponent: () => import('./simulations-light/page/simulations-light.component').then(m => m.SimulationsLightComponent),
  },
  {
    path: 'test/:uid',
    loadComponent: () => import('./test/page/test/test.component').then(m => m.TestComponent),
    data: { test: {} },
  },
  {
    path: 'errors/:uid',
    loadComponent: () => import('./test/page/test/test.component').then(m => m.TestComponent),
    data: { test: {} },
  },
  {
    path: 'community',
    loadComponent: () => import('./community/page/community.component').then(m => m.CommunityComponent),
    data: { state: 'community' },
  },
  {
    path: 'archive',
    loadChildren: () => import('./archive/archive.module').then(m => m.ArchiveModule),
  },
  {
    path: 'live',
    loadChildren: () => import('./rec-room/rec-room.module').then(m => m.RecRoomModule),
    data: { state: 'live', is_rec_room: false },
  },
  {
    path: 'rec',
    loadChildren: () => import('./rec-room/rec-room.module').then(m => m.RecRoomModule),
    data: { is_rec_room: true },
  },
  {
    path: 'profile',
    loadComponent: () => import('./profile/page/profile.component').then(m => m.ProfileComponent),
  },
  {
    path: 'products',
    loadComponent: () => import('./products/page/products.component').then(m => m.ProductsComponent),
  },
  {
    path: 'product/:uid',
    loadComponent: () => import('./product/page/product.component').then(m => m.ProductComponent),
  },
  {
    path: 's/:token',
    loadComponent: () => import('./shorter/page/shorter.component').then(m => m.ShorterComponent),
  },
  {
    path: 'simulations',
    loadComponent: () => import('./prefabs/page/prefabs/prefabs.component').then(m => m.PrefabsComponent),
    data: { state: 'simulations' },
  },
  {
    path: 'folder',
    loadChildren: () => import('./question-folder/question-folder.module').then(m => m.QuestionFolderModule),
  },
  {
    path: 'flashcards/:uid',
    loadComponent: () => import('./flashcards/page/flashcards.component').then(m => m.FlashcardsComponent),
  },
  {
    path: 'interrogation/:uid',
    loadComponent: () => import('./interrogation/page/interrogation.component').then(m => m.InterrogationComponent),
  },
];

const gRoutes: Routes = [
  {
    path: '',
    canActivate: [loggedActivated],
    canActivateChild: [loggedChildActivated],
    component: BaseComponent,
    children: childRoutes,
  },
  {
    path: 'ssh/:token',
    loadComponent: () => import('./simulation-shorter/simulation-shorter.component').then(m => m.SimulationShorterComponent),
  },
  { path: 'scalapay', loadComponent: () => import('./scalapay/scalapay.component').then(m => m.ScalapayComponent) },
  {
    path: 'sh/:token',
    loadComponent: () => import('./shorter/page/shorter.component').then(m => m.ShorterComponent),
  },
  {
    path: 'coupon/:coupon',
    loadComponent: () => import('./coupon-route/coupon-route.component').then(m => m.CouponRouteComponent),
  },
  { path: '**', redirectTo: `/` },
];

@NgModule({
  imports: [RouterModule.forChild(gRoutes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
