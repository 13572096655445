import { AfterContentChecked, Component, DestroyRef, effect, ElementRef, HostListener, inject, OnDestroy, OnInit, signal, ViewChild } from '@angular/core';
import { ConfigService } from '../../../../core/config/config.service';
import { UiService } from '../../../../core/ui-service/ui.service';
import { forkJoin, Subscription } from 'rxjs';
import { UtilsService } from '../../../../core/utils-service/utils.service';
import { TranslocoService } from '@jsverse/transloco';
import { SidenavElement, sidenavElements } from '../../model/sidenav.model';
import { UserService } from '../../../../core/user-service/user.service';
import { UserpilotService } from '../../../../core/userpilot/userpilot.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { SettingsService } from '../../../../core/settings/settings.service';
import { SidenavService } from '../../service/sidenav.service';
import { BREAKPOINT_VALUES } from '@futura/futura-ui/breakpoint';
import { ANALYTICS_PROVIDER } from '@futura/futura-ui';
import { ProfileSectionComponent } from '../profile-section/profile-section.component';
import { HelpSectionComponent } from '../help-section/help-section.component';
import { SidenavElementComponent } from '../sidenav-element/sidenav-element.component';
import { ScrollWithWheelDirective } from '../../../scroll-with-wheel/directive/scroll-with-wheel/scroll-with-wheel.directive';
import { LogoSectionComponent } from '../logo-section/logo-section.component';
import { NgClass } from '@angular/common';
import { PermissionsService } from 'src/app/core/permissions-service/permissions.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  standalone: true,
  imports: [NgClass, LogoSectionComponent, ScrollWithWheelDirective, SidenavElementComponent, HelpSectionComponent, ProfileSectionComponent],
})
export class SidenavComponent implements OnInit, OnDestroy, AfterContentChecked {
  private readonly analyticsService = inject(ANALYTICS_PROVIDER);
  public showSidenav = signal(false);
  public collapsedSidenav = signal(false);
  public filteredSidenavElements = signal<Array<SidenavElement>>([]);
  public showProfileRoute = signal(true);
  public activeElement = signal<string | undefined>('');
  public showHelpSection = signal<boolean>(true);
  @ViewChild('variableBottomSizeSection', { read: ElementRef, static: false }) elementView?: ElementRef;
  public actualHeight = signal(window.innerHeight);
  private subscriptions = new Subscription();
  private currentWidth = window.innerWidth;

  public lockedPages = signal<string[]>([]);

  constructor(
    private uiService: UiService,
    private userService: UserService,
    private config: ConfigService,
    private utilsService: UtilsService,
    private translateService: TranslocoService,
    private userpilotService: UserpilotService,
    private router: Router,
    private settingsService: SettingsService,
    private sidenavService: SidenavService,
    private permissionsService: PermissionsService,
    private destroyRef: DestroyRef
  ) {
    this.updateSidenavElements();
    this.permissionsService.load().subscribe();

    effect(
      () => {
        const sidenavElements = this.filteredSidenavElements();

        console.log({ sidenavElements });

        forkJoin(sidenavElements.map(element => this.checkPage(element.routerLink)))
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(lockedElements => {
            const lockedPages: string[] = [];

            lockedElements.forEach(lockedElement => {
              const key = Object.keys(lockedElement)[0];
              if (lockedElement[key]) {
                lockedPages.push(key);
              }
            });

            this.lockedPages.set(lockedPages);

            // console.log({ lockedPages: this.lockedPages() });
          });
      },
      { allowSignalWrites: true }
    );
  }

  checkPage(page: string) {
    return this.permissionsService.checkPage(page).pipe(
      map(p => {
        return { [page]: !p };
      })
    );
  }

  ngOnInit(): void {
    this.subscriptions.add(this.uiService._onShowSidenav.subscribe(newValue => this.showSidenav.set(newValue)));

    this.subscriptions.add(
      this.settingsService.readSetting('sidenav').subscribe(sidenavSettings => {
        if (sidenavSettings.open != null && !sidenavSettings.open) {
          this.collapsedSidenav.set(true);
        }
      })
    );

    // First time app opening
    this.setActiveFromRouteUrl(this.router.url);

    // Every subsequent route change
    this.subscriptions.add(
      this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
        if (event instanceof NavigationEnd) {
          const baseUrl = event.url.split('?')[0];
          this.setActiveFromRouteUrl(baseUrl);
        }
      })
    );
    this.showHelpSection.set(this.config.showHelp);
    // this.permissionsService.load().subscribe(() => {

    // })
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public ngAfterContentChecked(): void {
    this.computeDynamicHeight();
  }

  public closeSidenav(): void {
    this.uiService.showSidenav = false;
  }

  // TODO: Move this to a service
  public updateSidenavElements(): void {
    // Funzione per aggiornare gli elementi del sidenav

    // Si nascondono già gli elementi salvati nel local storage
    const hiddenElements = JSON.parse(localStorage.getItem('hidden_sidenav_elements') || '[]') || [];
    this.showProfileRoute.set(!hiddenElements.includes('profile'));
    this.filteredSidenavElements.set(
      sidenavElements.filter(element => {
        return !hiddenElements.includes(element.routerLink);
      })
    );

    // Si prendono tutti gli elementi del sidenav
    const elements = sidenavElements;
    this.showProfileRoute.set(
      !this.filteredSidenavElements()
        .map(element => element.routerLink)
        .includes('profile')
    );

    // Si ottengono le informazioni dell'utente, con le configurazioni delle sezioni
    this.userService.getFuturaUser().subscribe({
      next: user => this.uiService.onUserImageUpdated.emit(this.userService.getUserImage(user)),
      error: error => console.error(error),
    });

    this.sidenavService.hiddenPages().subscribe(toHide => {
      localStorage.removeItem('hidden_sidenav_elements');
      localStorage.setItem('hidden_sidenav_elements', JSON.stringify(toHide));
      this.filteredSidenavElements.set(
        elements.filter(element => {
          return !toHide?.includes(element.routerLink);
        })
      );
      this.showProfileRoute.set(!hiddenElements?.includes('profile'));
    });
  }

  public findElementOrSetAllInactive(routerLink: string): SidenavElement | undefined {
    for (const element of this.filteredSidenavElements()) {
      if (element.routerLink == routerLink) {
        return element;
      }
    }
    return undefined;
  }

  public sendElementEvent(element: SidenavElement): void {
    // Send events
    this.analyticsService?.sendAnalytics({
      eventName: 'menu_piattaforma',
      eventParams: {
        label: this.translateService.translate(element.name + '.title'),
      },
    });

    if (this.lockedPages().includes(element.routerLink)) {
      this.userService.missingLicenseDialog({ name: element.name, type: 'page' });
    } else {
      this.router.navigate([element.routerLink]).catch(console.error);
    }
  }

  public setActive(element?: SidenavElement): void {
    this.activeElement.set(element?.routerLink);
  }

  public isUserPilotRunning(): boolean {
    return this.userpilotService.isActive();
  }

  @HostListener('window:resize')
  private computeDynamicHeight(): void {
    this.actualHeight.set(window.innerHeight - this.elementView?.nativeElement.offsetHeight);

    // laptop-sm
    this.currentWidth = window.innerWidth;
    if (this.currentWidth < BREAKPOINT_VALUES.xl) {
      this.collapsedSidenav.set(true);
    }
  }

  private setActiveFromRouteUrl(url: string): void {
    const currentLocation = url.split('/').join('');
    const currentElement = this.findElementOrSetAllInactive(currentLocation);
    this.setActive(currentElement);
  }
}
