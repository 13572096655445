import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth-service/auth.service';

@Component({
  selector: 'app-auth-logout',
  templateUrl: './auth-logout.component.html',
  styleUrls: ['./auth-logout.component.scss'],
  standalone: true,
})
export class AuthLogoutComponent implements OnInit {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    // Quando questo componente viene chiamato in automatico viene eseguito il logout con funzione proprietaria
    this.authService.logout().subscribe(() => {
      // Quando il logout è stato eseguito con successo riporta l'utente sulla pagina di Login
      this.router.navigate(['login']);
      localStorage.clear();
      sessionStorage.clear();
    });
  }
}
