<div (click)="closeSidenav()" [ngClass]="{ 'd-none': !showSidenav(), 'overlay-blur': showSidenav() }"></div>
<div [ngClass]="{ 'mobile-open': showSidenav(), collapsed: collapsedSidenav(), 'userpilot-active': isUserPilotRunning() }" class="sidenav">
  <app-logo-section [(collapsed)]="collapsedSidenav" class="w-100"></app-logo-section>

  <div [style.height.px]="actualHeight()" class="wrapper-sidenav">
    @if (filteredSidenavElements().length > 0) {
      <div class="wrapper-icon-sidenav" appScrollWithWheel scrollDirection="y" scrollButtonColor="var(--fut-primary-500)">
        @for (element of filteredSidenavElements(); track element) {
          @if (element.routerLink !== 'profile') {
            <app-sidenav-element
              class="wrapper-icon"
              [id]="'sidenav-' + element.routerLink"
              [element]="element"
              [locked]="lockedPages().includes(element.routerLink)"
              [collapsed]="collapsedSidenav()"
              [activeElement]="activeElement()"
              (click)="sendElementEvent(element)"></app-sidenav-element>
          }
        }
      </div>
    }
  </div>

  <div #variableBottomSizeSection class="fixed-content">
    <div class="separator"></div>
    @if (showHelpSection()) {
      <app-help-section [collapsed]="collapsedSidenav()"></app-help-section>
    }
    <app-profile-section
      (closeSidenav)="closeSidenav()"
      [activeElement]="activeElement()"
      [collapsed]="collapsedSidenav()"
      [showProfileRoute]="showProfileRoute()"></app-profile-section>
  </div>
</div>
