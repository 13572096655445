import { Component, inject, output } from '@angular/core';
import { AliceUiService } from '../../service/alice-ui/alice-ui.service';
import { ConfigService } from 'src/app/core/config/config.service';
import { UserService } from 'src/app/core/user-service/user.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { ANALYTICS_PROVIDER } from '@futura/futura-ui';
import { TranslocoPipe } from '@jsverse/transloco';
import { AliceAnimationsComponent } from '../../../alice-animations/alice-animations.component';
import { NgClass } from '@angular/common';

const ALICE_PAGES_VISIBILITY = ['simulations', 'metrics', 'statistics', 'community', 'live', 'rec', 'archive', 'planner', 'profile'];

@Component({
  selector: 'app-alice-chat-button',
  templateUrl: './alice-chat-button.component.html',
  styleUrls: ['./alice-chat-button.component.scss'],
  standalone: true,
  imports: [NgClass, AliceAnimationsComponent, TranslocoPipe],
})
export class AliceChatButtonComponent {
  private readonly analyticsService = inject(ANALYTICS_PROVIDER);
  public isOpen = false;
  public showAliceButton = false;
  public showAlicePage = false;

  public readonly buttonVisibility = output<boolean>();
  public forcedStatusVisibility = true;

  constructor(
    private aliceUiService: AliceUiService,
    private configService: ConfigService,
    private userService: UserService,
    private router: Router
  ) {
    const baseShowAliceButton = this.configService.showAliceV2;
    const alice = this.configService.aliceLabels;

    if (!alice || !alice.lables) {
      this.showAliceButton = baseShowAliceButton;
      return;
    }

    this.userService.getLabel().subscribe(user_labels => {
      this.showAliceButton = baseShowAliceButton && this.userService.checkLables(user_labels, alice?.lables, alice?.whitelist);
    });

    this.checkPage(location.pathname);

    this.aliceUiService.forcedVisibility.subscribe((show: boolean) => {
      this.forcedStatusVisibility = show;
      this.checkPage(location.pathname);
    });

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(event => event as NavigationEnd)
      )
      .subscribe((_: NavigationEnd) => {
        this.checkPage(location.pathname);
      });
  }

  public open(): void {
    this.isOpen = true;

    const chatButton = this.aliceUiService.open();

    chatButton.afterOpened().subscribe(() => this.analyticsService?.sendAnalytics({ eventName: 'action_gpt_v2_chat_start' }));
    chatButton.afterDismissed().subscribe(() => (this.isOpen = false));
  }

  public checkPage(page: string): void {
    if (page.startsWith('/')) page = page.slice(1);
    this.showAlicePage = ALICE_PAGES_VISIBILITY.includes(page) && this.forcedStatusVisibility;
    this.buttonVisibility.emit(this.showAliceButton && this.showAlicePage);
  }
}
