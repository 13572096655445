import { Component, computed, inject, model, signal } from '@angular/core';
import { BaseDialogComponent } from '@futura/futura-ui/dialog';
import { IconTitleComponent } from '@futura/futura-ui/icon-title';
import { TranslocoDirective } from '@jsverse/transloco';
import { COMPONENT_STATE, ComponentState } from '@futura/futura-ui/ghost';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { extractVerticalFromBaseUrl } from '../../../core/util/vertical.util';
import { readCookie, deleteCookie } from '../../../core/util/cookie.util';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs/operators';
import { ConfigService } from '../../../core/config/config.service';

@Component({
  selector: 'app-change-vertical-dialog',
  standalone: true,
  imports: [BaseDialogComponent, IconTitleComponent, TranslocoDirective, MatFormField, MatLabel, MatOption, MatSelect, FormsModule],
  templateUrl: './change-vertical-dialog.component.html',
  styleUrl: './change-vertical-dialog.component.scss',
})
export class ChangeVerticalDialogComponent {
  private readonly configService = inject(ConfigService);

  protected readonly state = signal<ComponentState>(COMPONENT_STATE.NONE);
  protected readonly selectedVertical = model<string | undefined>(undefined);
  protected readonly verticals = toSignal(this.configService.getVerticals().pipe(map(verticals => verticals.filter(v => !v.disabled))));
  private readonly verticalCookie = signal<string | null>(readCookie('Vertical'));

  protected readonly currentVertical = computed<string | null>(() => {
    const verticals = this.verticals();
    if (!verticals) {
      return null;
    }

    const vertical = extractVerticalFromBaseUrl(window.location.origin, verticals);
    if (vertical) {
      vertical.value;
    }
    return null;
  });

  protected readonly verticalsFiltered = computed(() => {
    const verticals = this.verticals();
    if (!verticals) {
      return null;
    }
    const currentVertical = this.currentVertical();
    if (!currentVertical) {
      return verticals;
    }
    return verticals.filter(v => v.value !== currentVertical);
  });

  public changeVertical(): void {
    const selectedVerticalValue = this.selectedVertical();
    if (!selectedVerticalValue) {
      return;
    }
    const vertical = this.verticalsFiltered()?.find(v => v.value === selectedVerticalValue);
    if (!vertical) {
      return;
    }
    if (this.verticalCookie()) {
      deleteCookie('Vertical');
    }
    window.location.href = vertical.url;
  }
}
