<div [matMenuTriggerFor]="helpMenu" [ngClass]="{ collapsed: collapsed() }" class="wrapper-icon">
  <mat-icon class="icon-sidenav" fontSet="material-symbols-outlined">help</mat-icon>
  <span class="title-sidenav">{{ 'sidenav.help' | transloco }}</span>
</div>

<mat-menu #helpMenu="matMenu">
  <ng-template matMenuContent>
    <fut-button-menu-content>
      <fut-button-menu-element
        (click)="reportIssue()"
        [elementLabel]="'sidenav.help.report_issue' | transloco"
        [iconInfo]="{ icon: 'chat_error', fontSet: 'material-symbols-outlined' }">
      </fut-button-menu-element>

      <fut-button-menu-element
        (click)="openAlice()"
        [elementLabel]="'sidenav.help.alice' | transloco"
        [iconInfo]="{ icon: 'auto_awesome', fontSet: 'material-symbols-outlined' }">
      </fut-button-menu-element>

      <fut-button-menu-element
        (click)="openGeneralSupport()"
        [elementLabel]="'sidenav.help.support' | transloco"
        [iconInfo]="{ icon: 'help', fontSet: 'material-symbols-outlined' }">
      </fut-button-menu-element>

      @if (!isMobileDevice()) {
        <fut-button-menu-element
          [iconInfo]="{ icon: 'info', fontSet: 'material-symbols-outlined' }"
          [elementLabel]="'sidenav.help.tutorial' | transloco"
          (click)="restartTutorial()">
        </fut-button-menu-element>
      }
    </fut-button-menu-content>
  </ng-template>
</mat-menu>
