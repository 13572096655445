import { Component, computed, inject, signal } from '@angular/core';
import { deleteCookie, readCookie } from '../../../core/util/cookie.util';
import { BaseDialogComponent } from '@futura/futura-ui/dialog';
import { IconTitleComponent } from '@futura/futura-ui/icon-title';
import { COMPONENT_STATE, ComponentState } from '@futura/futura-ui/ghost';
import { TranslocoDirective } from '@jsverse/transloco';
import { MatDialogRef } from '@angular/material/dialog';
import { extractVerticalFromBaseUrl } from '../../../core/util/vertical.util';
import { TranslateModel } from '@futura/futura-ui/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs/operators';
import { ConfigService } from '../../../core/config/config.service';
import { AnalyticsButtonDirective } from '@futura/futura-ui/analytics';

@Component({
  selector: 'app-check-vertical-dialog',
  standalone: true,
  imports: [BaseDialogComponent, IconTitleComponent, TranslocoDirective, AnalyticsButtonDirective],
  templateUrl: './check-vertical-dialog.component.html',
  styleUrl: './check-vertical-dialog.component.scss',
})
export class CheckVerticalDialogComponent {
  private readonly dialogRef = inject(MatDialogRef<CheckVerticalDialogComponent>);
  private readonly configService = inject(ConfigService);

  public readonly verticalCookie = signal<string | null>(readCookie('Vertical'));
  protected readonly state = signal<ComponentState>(COMPONENT_STATE.NONE);
  private readonly verticals = toSignal(this.configService.getVerticals().pipe(map(verticals => verticals.filter(v => !v.disabled))));

  public readonly verticalCookieName = computed(() => {
    const vertical = this.verticalCookie();
    if (!vertical) {
      return undefined;
    }
    return this.verticals()?.find(v => v.value === vertical)?.name;
  });

  public readonly currentVertical = computed<string | null>(() => {
    const verticals = this.verticals();
    if (!verticals) {
      return null;
    }

    const vertical = extractVerticalFromBaseUrl(window.location.origin, verticals);
    if (vertical) {
      return vertical.name;
    }
    return null;
  });

  public readonly subtitle = computed(() => {
    if (!this.verticalCookie() || !this.currentVertical()) {
      return undefined;
    }
    return {
      text: 'dialogs.check-vertical.subtitle',
      translate: true,
      translateArgs: { usualVertical: this.verticalCookie(), currentVertical: this.currentVertical() },
    } as TranslateModel;
  });

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public changeVertical(): void {
    const verticalCookieValue = this.verticalCookie();
    if (!verticalCookieValue) {
      return;
    }
    const baseUrl = this.verticals()?.find(v => v.value === verticalCookieValue)?.url;
    if (!baseUrl) {
      return;
    }
    deleteCookie('Vertical');
    window.location.href = baseUrl;
  }
}
