<div [ngClass]="{ open: chat_opened }" class="alice-chat-wrapper">
  <div class="alice-chat-container scrollbar-hide" id="alice-chat">
    <div class="alice-chat-title">
      <div class="d-flex align-items-center">
        <h1 class="fut-h1 m-0">Alice</h1>
        <mat-icon (click)="openInfoDialog()" class="pointer ml-1" fontSet="material-symbols-outlined">info</mat-icon>
      </div>
      <mat-icon fontSet="material-symbols-outlined" (click)="stopChat()" class="pointer">close</mat-icon>
    </div>
    @if (chat) {
      <div class="alice-chat-content scrollbar-hide" id="alice-chat-content">
        @for (message of chat.messages; track message) {
          <div [ngClass]="{ 'alice-message-container': message.role === 'assistant', 'user-message-container': message.role === 'user' }">
            @if (message.role === 'assistant') {
              <div class="alice-img">
                <app-alice-animations [type]="'avatar'"></app-alice-animations>
              </div>
            }
            <div
              [ngClass]="{ 'alice-message': message.role === 'assistant', 'user-message': message.role === 'user' }"
              [innerHTML]="message.role === 'user' ? hideMessageInformation(message.content) : message.content"></div>
          </div>
        }
        @if (chat.generating_answer && alice_status !== 'sent') {
          <div class="alice-message-container">
            <div class="alice-img">
              <app-alice-animations [type]="'avatar'"></app-alice-animations>
            </div>
            <div class="alice-message w-100">
              @if (chat.messages.length == 0) {
                <div class="d-flex align-items-center w-100 mb-1">
                  <mat-icon fontSet="material-symbols-outlined" class="mr-1 fut-text-opacity">auto_awesome</mat-icon>
                  @if (chat.type === 'question') {
                    <span class="small">Sto riformulando la spiegazione...</span>
                  }
                  @if (chat.type === 'simulation_tip') {
                    <span class="small">Sto elaborando un suggerimento...</span>
                  }
                </div>
              }
              @if (alice_status === 'deleyed') {
                <div>Scusa per l'attesa.<br />In alcuni casi l'elaborazione può richiedere un po' di tempo.<br /><br />Grazie per la pazienza.</div>
              }
              @if (alice_status === 'error') {
                <div style="white-space: prewrap">
                  Mi dispiace, al momento non riesco generare una risposta a causa dell'elevato numero di richieste.<br /><br />Riprova tra qualche minuto.<br />Grazie
                  per la pazienza!
                </div>
              }
            </div>
          </div>
        }
        @if (chat.generating_answer) {
          <div class="alice-message-container">
            <div class="alice-img">
              <app-alice-animations [type]="'avatar'"></app-alice-animations>
            </div>
            <div class="alice-message w-100">
              @if (chat.messages.length == 0 && alice_status === 'sent') {
                <div class="d-flex align-items-center w-100 mb-1">
                  <mat-icon fontSet="material-symbols-outlined" class="mr-1 fut-text-opacity">auto_awesome</mat-icon>
                  @if (chat.type === 'question') {
                    <span class="small">Sto riformulando la spiegazione...</span>
                  }
                  @if (chat.type === 'simulation_tip') {
                    <span class="small">Sto elaborando un suggerimento...</span>
                  }
                </div>
              }
              @for (len of random_skeletons_length; track len) {
                <div class="fut-skeleton fut-skeleton-text" [ngStyle]="{ 'width.%': len }"></div>
              }
            </div>
          </div>
        }
        <!-- <div class="user-message-container">
        <div class="user-message">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum deserunt explicabo veritatis omnis alias neque fugiat nam sed quidem aut. Voluptates ut vitae sit fugit aut reiciendis iste magni suscipit?
        </div>
      </div> -->
        @if (chat.messages.length >= 5 && last_message && !animating_message) {
          <div class="alice-too-many-messages">
            <hr />
            <span>Al momento Alice è limitata a 3 risposte. Siamo al lavoro sulla nuova versione.</span>
            <hr />
          </div>
        }
        @if (last_message && !animating_message && chat.messages.length < 5 && !chat.lock_response) {
          <div class="user-possible-messages">
            @for (ans of last_message.possibile_answers; track ans; let i = $index) {
              <span
                class="alice-chip"
                [ngClass]="{ 'd-flex align-items-center justify-content-between': i == (last_message.possibile_answers || []).length - 1 && special_message }"
                (click)="response(ans)">
                <mat-icon fontSet="material-symbols-outlined">auto_awesome</mat-icon>
                <span class="text-left">{{ hideMessageInformation(ans) }}</span>
                <mat-icon fontSet="material-symbols-outlined">auto_awesome</mat-icon>
              </span>
            }
          </div>
        }
      </div>
    }
    <div class="alice-chat-footer">
      <div class="text-center w-100">
        <span class="small">Alice potrebbe generare informazioni inaccurate</span>
      </div>
    </div>
  </div>
</div>
<div (click)="stopChat()" class="alice-chat-backdrop"></div>
