import { VerticalInfo } from '../model/vertical.model';

export function extractVerticalFromBaseUrl(baseUrl: string, verticals: VerticalInfo[]): VerticalInfo | null {
  console.log(baseUrl);
  const vertical = verticals.find(v => v.url.includes(baseUrl));
  if (vertical) {
    return vertical;
  }
  return null;
}
